import { Elements } from 'prismic-reactjs';
import { useState, useEffect } from 'react';
import textToIdString from '@utils/textToIdString';
import {
  PrismicDocPageDataBodySlicesType,
  PrismicStructuredTextType,
} from '@utils/typings/prismic-types';

export interface RawSpan {
  type: Elements;
  text: string;
  spans: RawSpan;
}

export function useCurrentHeader(slices: PrismicDocPageDataBodySlicesType[]) {
  const allHeaders = slices
    .filter((slice) => slice.slice_type === 'docs_rich_text')
    .flatMap((slice: any) =>
      slice.primary.rich_text.richText.filter((span: RawSpan) =>
        [
          Elements.heading2,
          Elements.heading3,
          Elements.heading4,
          Elements.heading5,
        ].includes(span.type),
      ),
    );

  return useScrolledHeaderId(allHeaders);
}

// We need to use this method to get the current scroll id because
// we can't use refs to get the dynamic content from Prismic.
function useScrolledHeaderId(headers: PrismicStructuredTextType[]) {
  const [currentHeader, setCurrentHeader] = useState('');
  const isLoaded = useLoaded();

  useEffect(() => {
    if (!isLoaded) return undefined;

    const ids = headers
      .map((header: PrismicStructuredTextType) => header.text)
      .map(textToIdString)
      .filter(Boolean);
    const elements = ids.map((id) => document.getElementById(id));
    function trackPosition() {
      const tops = elements.map(
        (element) => element.getBoundingClientRect().top,
      );
      const currentIndex = tops.reduce(
        (index, top, i) => (top < 100 ? i : index),
        0,
      );
      setCurrentHeader(ids[currentIndex]);
    }
    trackPosition();
    document.addEventListener('scroll', trackPosition);
    return () => document.removeEventListener('scroll', trackPosition);
  }, [headers, isLoaded]);

  return currentHeader;
}

// We need this because we can't work with react refs, and need to make sure
// page is loaded before we can grab the elements with getElementById.
function useLoaded() {
  const [documentLoaded, setDocumentLoaded] = useState(false);

  useEffect(() => {
    function isDocumentLoaded() {
      if (document.readyState === 'complete') {
        setDocumentLoaded(true);
      }
    }
    isDocumentLoaded();

    document.addEventListener('readystatechange', isDocumentLoaded);
    return () =>
      document.removeEventListener('readystatechange', isDocumentLoaded);
  }, []);
  return documentLoaded;
}
