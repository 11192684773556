import { Elements, RichText } from 'prismic-reactjs';
import React from 'react';
import StickyBox from 'react-sticky-box';
import textToIdString from '@utils/textToIdString';
import {
  PrismicDocPageDataBodyDocsRichText,
  PrismicDocPageDataBodySlicesType,
} from '@utils/typings/prismic-types';
import NavLink from './NavLink';
import * as styles from './styles.module.scss';
import { RawSpan, useCurrentHeader } from './useCurrentHeader';

interface DocPageNavProps {
  slices: PrismicDocPageDataBodySlicesType[];
}

// Use this type for all SliceZone components
interface SliceComponentsProps {
  [key: string]: React.ReactNode;
}

export default function DocPageNav({ slices }: DocPageNavProps) {
  const currentHeaderId = useCurrentHeader(slices);
  const sliceComponents: SliceComponentsProps = {
    docs_rich_text: DocNavSlice,
  };

  return (
    <nav className={styles.nav}>
      <StickyBox offsetTop={96} offsetBottom={24}>
        <ul className={styles.navList}>
          {slices?.map((slice, index) => {
            const SliceComponent = sliceComponents[slice.slice_type];
            if (!SliceComponent) {
              return null;
            }
            return (
              // @ts-ignore
              <SliceComponent
                key={`doc-nav-${index}`}
                slice={slice}
                currentHeaderId={currentHeaderId}
              />
            );
          })}
        </ul>
      </StickyBox>
    </nav>
  );
}

interface DocNavSliceProps {
  slice: PrismicDocPageDataBodyDocsRichText;
  currentHeaderId: string;
}

function DocNavSlice({ slice, currentHeaderId }: DocNavSliceProps) {
  const headers = slice.primary.rich_text.richText.filter((span: RawSpan) =>
    [
      Elements.heading2,
      Elements.heading3,
      Elements.heading4,
      Elements.heading5,
    ].includes(span.type),
  );
  return (
    <RichText
      render={headers}
      htmlSerializer={customHtmlSerializer(currentHeaderId)}
    />
  );
}

function customHtmlSerializer<T>(currentScrollId: string) {
  return (...props: SerializerProps<T>) =>
    htmlSerializer(currentScrollId, ...props);
}

type SerializerProps<T> = [
  type: Elements,
  element: any,
  content: string,
  children: T[],
  key: string,
];

function htmlSerializer<T>(
  currentScrollId: string,
  type: Elements,
  element: any,
  content: string,
  children: T[],
  key: string,
) {
  switch (type) {
    case Elements.heading2:
    case Elements.heading3:
    case Elements.heading4:
    case Elements.heading5: {
      const id = textToIdString(element.text);
      return (
        <NavLink
          key={key}
          id={id}
          highlighted={id === currentScrollId}
          className={styles[type]}
        >
          {element.text}
        </NavLink>
      );
    }

    default:
      // Always include a default that returns null
      return null;
  }
}
