import clsx from 'clsx';
import React from 'react';
import * as styles from './styles.module.scss';

interface NavLinkProps {
  id: string;
  highlighted?: boolean;
  className?: string;
  children: React.ReactNode;
}
export default function NavLink({
  id,
  highlighted = false,
  className,
  children,
}: NavLinkProps) {
  return (
    <li
      className={clsx(
        styles.navItem,
        className,
        highlighted && styles.scrolledPast,
      )}
    >
      <a
        href={`#${id}`}
        onClick={(e) => {
          e.preventDefault();
          const el = document.getElementById(id);
          el.scrollIntoView({ behavior: 'smooth' });
        }}
        className={clsx()}
      >
        {children}
      </a>
    </li>
  );
}
