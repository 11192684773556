import clsx from 'clsx';
import React from 'react';
import { Link } from 'gatsby';
import * as styles from './styles.module.scss';

interface DocItemProps {
  url: string;
  title: string;
  active: boolean;
  className: string;
}
export default function DocItem({
  url,
  title,
  active,
  className,
}: DocItemProps) {
  return (
    <li className={styles.docItem}>
      <Link
        to={url}
        className={clsx(
          className,
          styles.docLink,
          active ? styles.active : styles.inactive,
        )}
      >
        {title}
      </Link>
      {active && <span className={styles.highlight} />}
    </li>
  );
}
