import React from 'react';
import useLocalStorage from '@utils/hooks/useLocalStorage';
import ThumbDown from '@icons/thumb-down.svg';
import ThumbUp from '@icons/thumb-up.svg';
import * as styles from './styles.module.scss';

declare global {
  interface Window {
    posthog: any;
    analytics: any;
  }
}

enum Feedback {
  Up,
  Down,
}

export default function DocFeedback({ id }: { id: string }) {
  const [feedback, setFeedback] = useLocalStorage<Feedback>(
    `feedback-${id}`,
    null,
  );
  return (
    <div className={styles.feedback}>
      <h2 className={styles.title}>Was this article helpful?</h2>
      <div className={styles.buttons}>
        <FeedbackButton
          style={feedback === Feedback.Up ? { color: '#4CAF50' } : {}}
          onClick={() => {
            sendFeedback(Feedback.Up);
            setFeedback(Feedback.Up);
          }}
        >
          <ThumbUp />
        </FeedbackButton>
        <FeedbackButton
          style={feedback === Feedback.Down ? { color: '#F44336' } : {}}
          onClick={() => {
            sendFeedback(Feedback.Down);
            setFeedback(Feedback.Down);
          }}
        >
          <ThumbDown />
        </FeedbackButton>
      </div>
    </div>
  );
}

interface FeedbackButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}
function FeedbackButton({ children, ...props }: FeedbackButtonProps) {
  return (
    <button type="button" className={styles.button} {...props}>
      {children}
    </button>
  );
}

function sendFeedback(feedback: Feedback) {
  if (typeof window?.posthog === 'undefined') {
    return;
  }
  feedback === Feedback.Up
    ? window.posthog.capture('Feedback Good')
    : window.posthog.capture('Feedback Bad');
}
