import { useLayoutEffect, useState } from 'react';

// Find an element's size after layout
// https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
export default function useBoundingClientRect(
  ref: React.MutableRefObject<HTMLInputElement>,
) {
  const [clientRect, setClientRect] = useState<DOMRect>(null);

  useLayoutEffect(() => {
    if (!ref.current) return null;
    function measure() {
      // We use a timeout here to stick the function on the end of
      // the event loop because sometimes it would fire before rending
      // which should not happen for useLayoutEffect ¯\_(ツ)_/¯
      setTimeout(() => setClientRect(ref.current.getBoundingClientRect()), 0);
    }

    measure();

    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  }, [ref]);

  return clientRect;
}
