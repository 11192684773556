import { navigate } from '@reach/router';
import algoliasearch from 'algoliasearch/lite';
import clsx from 'clsx';
import React, { useState, useRef } from 'react';
import { BasicDoc, Hit } from 'react-instantsearch-core';
import {
  connectAutoComplete,
  InstantSearch,
  Snippet,
  Highlight,
} from 'react-instantsearch-dom';
import Link from '@components/Link';
import useBoundingClientRect from '@utils/hooks/useBoundingClientRect';
import useOnClickOutside from '@utils/hooks/useOnClickOutside';
import { DocIdMap } from '@utils/typings/custom';
import { PrismicDocsSitemapFragmentFragment } from '@utils/typings/prismic-types';
import AlgoliaLogo from '@icons/algolia-logo.svg';
import CloseIcon from '@icons/close-2.svg';
import EnterIcon from '@icons/enter.svg';
import RangeArrowIcon from '@icons/range-arrow.svg';
import SearchIcon from '@icons/search.svg';
import * as styles from './styles.module.scss';

const MAX_RESULTS = 5;

const searchClient = algoliasearch(
  'IK0Q7FW3LX',
  'b34606a8538f06258b0acec77bca1800',
);

interface DocsSearchProps {
  docsSitemap?: PrismicDocsSitemapFragmentFragment;
  docIdMap: DocIdMap;
}

export default function DocsSearch({ docsSitemap, docIdMap }: DocsSearchProps) {
  return (
    <InstantSearch indexName="pry_docs" searchClient={searchClient}>
      <CustomAutocomplete docsSitemap={docsSitemap} docIdMap={docIdMap} />
    </InstantSearch>
  );
}

interface AutocompleteProps {
  hits: Array<Hit<BasicDoc>>;
  currentRefinement: string;
  refine(value?: string): void;
  docsSitemap?: PrismicDocsSitemapFragmentFragment;
  docIdMap: DocIdMap;
}
function Autocomplete({
  hits,
  currentRefinement,
  refine,
  docsSitemap,
  docIdMap,
}: AutocompleteProps) {
  const [index, setIndex] = useState<number>(null);
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const rect = useBoundingClientRect(inputRef);
  useOnClickOutside(autocompleteRef, () => refine(''));

  const showDropdown = currentRefinement && !!hits.length;

  return (
    <div ref={autocompleteRef} className={styles.autocomplete}>
      <div className={styles.search}>
        <div className={styles.searchIcon}>
          <SearchIcon />
        </div>
        <input
          ref={inputRef}
          value={currentRefinement}
          onChange={(event) => refine(event.currentTarget.value)}
          onKeyDown={({ key }) => {
            switch (key) {
              case 'ArrowDown':
                setIndex(
                  index === null
                    ? 0
                    : (index + 1) % Math.min(hits.length, MAX_RESULTS),
                );
                break;
              case 'ArrowUp':
                setIndex(
                  index === null
                    ? hits.length - 1
                    : (index + hits.length - 1) %
                        Math.min(hits.length, MAX_RESULTS),
                );
                break;
              case 'Enter':
                {
                  const prismicId = hits[index].objectID;
                  const url = docIdMap[prismicId].url;
                  navigate(url);
                }
                break;
              case 'Escape':
                setIndex(null);
                refine('');
                break;
              default:
                break;
            }
          }}
          className={clsx(styles.input, showDropdown && styles.inputOpen)}
        />
        {currentRefinement && (
          <button
            type="button"
            onClick={() => refine('')}
            className={styles.closeIcon}
          >
            <CloseIcon />
          </button>
        )}
      </div>
      {showDropdown && (
        <div style={{ width: rect?.width }} className={styles.resultDropdown}>
          <span className={styles.dropdownSeparator} />
          <ul className={styles.results}>
            {hits.slice(0, MAX_RESULTS).map((hit, i) => {
              const section = docsSitemap.data.body.find((category) =>
                category.items.some(
                  (item) => item.doc_page.id === hit.objectID,
                ),
              );
              const sectionName = section?.primary.name.text;
              return (
                <ResultItem
                  key={hit.objectID}
                  hit={hit}
                  sectionName={sectionName}
                  isSelected={i === index}
                  url={docIdMap[hit.objectID].url}
                />
              );
            })}
          </ul>
          <Shortcuts />
        </div>
      )}
    </div>
  );
}

const CustomAutocomplete = connectAutoComplete(Autocomplete);

interface ResultItemProps {
  hit: Hit;
  sectionName?: string;
  isSelected: boolean;
  url: string;
}
function ResultItem({ hit, sectionName, isSelected, url }: ResultItemProps) {
  return (
    <li className={clsx(styles.resultItem, isSelected && styles.selected)}>
      <Link href={url}>
        <div className={styles.sectionAndTitle}>
          {sectionName && (
            <span className={styles.sectionName}>{`${sectionName} /`}</span>
          )}
          <Highlight
            hit={hit}
            attribute="title"
            className={styles.resultTitle}
          />
        </div>
        <Snippet hit={hit} attribute="body" className={styles.resultBody} />
      </Link>
      {isSelected && (
        <div className={styles.selectedEnterIcon}>
          <EnterIcon />
        </div>
      )}
    </li>
  );
}

function Shortcuts() {
  return (
    <li className={styles.shortcuts}>
      <div className={styles.instructions}>
        <Instruction>
          <Icon>
            <EnterIcon />
          </Icon>
          <span>to select</span>
        </Instruction>
        <Instruction>
          <Icon>
            <div style={{ transform: 'rotate(-90deg)' }}>
              <RangeArrowIcon />
            </div>
          </Icon>
          <Icon>
            <div style={{ transform: 'rotate(90deg)' }}>
              <RangeArrowIcon />
            </div>
          </Icon>
          <span>to navigate</span>
        </Instruction>
        <Instruction>
          <Icon>
            <span className={styles.textIcon}>esc</span>
          </Icon>
          <span>to close</span>
        </Instruction>
      </div>
      <div className={styles.algoliaLogo}>
        <AlgoliaLogo />
      </div>
    </li>
  );
}

interface InstructionProps {
  children: React.ReactNode;
}
function Instruction({ children }: InstructionProps) {
  return <div className={styles.instruction}>{children}</div>;
}

interface IconProps {
  children: React.ReactNode;
}
function Icon({ children }: IconProps) {
  return <div className={styles.icon}>{children}</div>;
}
