// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--4oJ-z";
export var autocomplete = "styles-module--autocomplete--afkVT";
export var search = "styles-module--search--MWqIA";
export var searchIcon = "styles-module--search-icon--Vrn6D";
export var input = "styles-module--input--jmXbl";
export var inputOpen = "styles-module--input-open--CZ-7B";
export var closeIcon = "styles-module--close-icon--Wxapg";
export var resultDropdown = "styles-module--result-dropdown--tKMmy";
export var dropdownSeparator = "styles-module--dropdown-separator--4A9RW";
export var results = "styles-module--results--o7hsJ";
export var resultItem = "styles-module--result-item--BE4AJ";
export var sectionAndTitle = "styles-module--section-and-title--BFMxj";
export var sectionName = "styles-module--section-name--zYccU";
export var resultTitle = "styles-module--result-title--ZNdko";
export var resultBody = "styles-module--result-body--ToZJV";
export var selected = "styles-module--selected--n+CLm";
export var selectedEnterIcon = "styles-module--selected-enter-icon--5NhL2";
export var shortcuts = "styles-module--shortcuts--1kQNc";
export var instructions = "styles-module--instructions--Xi1H8";
export var instruction = "styles-module--instruction--oXgms";
export var icon = "styles-module--icon--syrIw";
export var textIcon = "styles-module--text-icon--zIgg+";
export var algoliaLogo = "styles-module--algolia-logo--K0FKn";