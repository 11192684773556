import React from 'react';
import { Link } from 'gatsby';
import ArrowRightIcon from '@icons/arrow-right-2.svg';
import * as styles from './styles.module.scss';

interface DocPrevNextNavProps {
  prev: {
    title: string;
    url: string;
  };
  next: {
    title: string;
    url: string;
  };
}

export default function DocPrevNextNav({ prev, next }: DocPrevNextNavProps) {
  return (
    <nav className={styles.nav}>
      {prev && (
        <NavLink
          sectionName={'Previous Section'}
          title={prev.title}
          url={prev.url}
        />
      )}
      {next && (
        <NavLink
          sectionName={'Next Section'}
          title={next.title}
          url={next.url}
        />
      )}
    </nav>
  );
}

interface NavLinkProps {
  sectionName: string;
  title: string;
  url: string;
}

function NavLink({ sectionName, title, url }: NavLinkProps) {
  return (
    <div className={styles.navItem}>
      <span className={styles.sectionName}>{sectionName}</span>
      <Link to={url} className={styles.link}>
        <span className={styles.title}>{title}</span>
        <div className={styles.arrowIcon}>
          <ArrowRightIcon />
        </div>
      </Link>
    </div>
  );
}
