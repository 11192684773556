import clsx from 'clsx';
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { graphql } from 'gatsby';
import { DocIdMap } from '@utils/typings/custom';
import { PrismicDocsSitemapFragmentFragment } from '@utils/typings/prismic-types';
import TwirlDown from '../../static/icons/twirl-down.svg';
import SitemapItem from './SitemapItem';
import * as styles from './styles.module.scss';

interface DocsSitemapProps {
  sitemap: PrismicDocsSitemapFragmentFragment;
  activeUrl: string;
  docIdMap: DocIdMap;
}

export default function DocsSitemap({
  sitemap,
  docIdMap,
  activeUrl,
}: DocsSitemapProps) {
  return (
    <nav>
      <ul>
        {sitemap.data.top_level_pages.map((item) => (
          <SitemapItem
            key={item.doc_page.id}
            url={item.doc_page.url}
            title={docIdMap[item.doc_page.id]?.title}
            active={activeUrl === item.doc_page.url}
            className={styles.topLevelLink}
          />
        ))}
        {sitemap.data.body.map((docsSection) => (
          <Folder
            key={docsSection.id}
            name={docsSection.primary.name.text}
            containsActiveDoc={docsSection.items.some(
              (item) => item.doc_page.url === activeUrl,
            )}
          >
            {docsSection.items.map((item) => (
              <SitemapItem
                key={item.doc_page.id}
                url={item.doc_page.url}
                title={docIdMap[item.doc_page.id]?.title}
                active={item.doc_page.url === activeUrl}
                className={styles.nestedLink}
              />
            ))}
          </Folder>
        ))}
      </ul>
    </nav>
  );
}

interface FolderProps {
  name: string;
  containsActiveDoc: boolean;
  children: React.ReactNode;
}
function Folder({ name, containsActiveDoc, children }: FolderProps) {
  const [open, setOpen] = useState(containsActiveDoc);
  return (
    <li>
      <button
        className={styles.folder}
        type="button"
        onClick={() => setOpen(!open)}
      >
        <span
          className={clsx(
            styles.folderName,
            containsActiveDoc && styles.folderActive,
          )}
        >
          {name}
        </span>
        <div
          className={styles.twirlDown}
          style={{ transform: `rotate(${open ? '0' : '-90'}deg)` }}
        >
          <TwirlDown />
        </div>
      </button>
      <Collapse isOpened={open}>
        <ul className={styles.itemList}>{children}</ul>
      </Collapse>
    </li>
  );
}

export const query = graphql`
  fragment PrismicDocsSitemapFragment on PrismicDocsSitemap {
    _previewable
    data {
      top_level_pages {
        doc_page {
          id
          url
        }
      }
      body {
        ... on PrismicDocsSitemapDataBodyDocsSection {
          id
          primary {
            name {
              text
            }
          }
          items {
            doc_page {
              id
              url
            }
          }
        }
      }
    }
  }
`;
